import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { FilterSearchField } from '/features/buildingBlocks/FilterSearchField'
import { OverviewFilters } from '/features/buildingBlocks/OverviewFilters'
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import iconLookingGlass from '/images/icons/icon-looking-glass.raw.svg'
import iconFunnel from '/images/icons/funnel.raw.svg'
import iconCross from '/images/icons/cross.raw.svg'

import styles from './MobileFilters.css'

export function MobileFilters({
  filtersAndValues,
  handleFilterChange,
  handleResetClick,
  totalCount,
  onSearch = undefined,
  searchQuery = undefined,
  placeholder = undefined
}) {
  return (
    <div className={styles.component}>
      {typeof searchQuery === 'string' && onSearch && (
        <Search q={searchQuery} {...{ onSearch, placeholder }} />
      )}

      <Filters {...{ handleFilterChange, handleResetClick, filtersAndValues, totalCount }} />
    </div>
  )
}

function Filters({ handleFilterChange, handleResetClick, filtersAndValues, totalCount }) {
  const { isOpen, setIsOpen, getReferenceProps, getFloatingProps, getFocusManagerProps } = useFloatingFilters()
  const { __ } = useTranslate()

  const activeFiltersCount = getActiveFiltersCount({ filtersAndValues })

  return (
    <>
      <IconButton
        icon={iconFunnel}
        text={__`filters-title`}
        onClick={() => setIsOpen(true)}
        layoutClassName={styles.iconButtonLayout}
        dataX='open-filters-overlay'
        {...getReferenceProps()}
      >
        {Boolean(activeFiltersCount) && (
          <FilterIndicator layoutClassName={styles.indicatorLayout} value={activeFiltersCount} />
        )}
      </IconButton>

      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay lockScroll>
            <FloatingFocusManager {...getFocusManagerProps()}>
              <FiltersOverlay
                onClose={() => setIsOpen(false)}
                {...{ handleFilterChange, handleResetClick, filtersAndValues, totalCount, getFloatingProps }}
              />
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  )

  function useFloatingFilters() {
    const [isOpen, setIsOpen] = React.useState(false)

    const { refs, context } = useFloating({
      whileElementsMounted: autoUpdate,
      onOpenChange: setIsOpen,
      open: isOpen
    })

    const focus = useFocus(context)
    const dismiss = useDismiss(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
      focus, dismiss
    ])

    return {
      isOpen,
      setIsOpen,
      getFloatingProps: x => getFloatingProps({ ref: refs.setFloating, ...x }),
      getReferenceProps: x => getReferenceProps({ ref: refs.setReference, ...x }),
      getFocusManagerProps: () => ({ context, restoreFocus: true, returnFocus: false }),
      context
    }
  }

  function getActiveFiltersCount({ filtersAndValues }) {
    return Object.entries(filtersAndValues)
      .map(([id, options]) => {
        return ['country', 'salary_scale_min', 'salary_scale_max'].includes(id)
          ? options.value === '' ? 0 : 1
          : options.value?.length
      })
      .reduce((result, x) => result + x)
  }
}

function FiltersOverlay({ handleFilterChange, handleResetClick, filtersAndValues, onClose, totalCount, getFloatingProps }) {
  return (
    <div className={styles.component_rootFiltersOverlay} {...getFloatingProps()}>
      <FiltersOverlayContent
        layoutClassName={styles.filtersOverlayContentLayout}
        {...{ handleFilterChange, handleResetClick, filtersAndValues, onClose, totalCount }}
      />
    </div>
  )
}

function FiltersOverlayContent({
  handleFilterChange,
  handleResetClick,
  filtersAndValues,
  onClose,
  layoutClassName,
  totalCount
}) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentFiltersOverlayContent, layoutClassName)}>
      <OverlayHeader title={__`filters-title`} {...{ onClose }} />
      <div className={styles.jobFilters}>
        <OverviewFilters
          onReset={handleResetClick}
          onFilterChange={handleFilterChange}
          {...{ filtersAndValues }}
        />
      </div>
      <div className={styles.resultsButton}>
        <ButtonPrimary
          layoutClassName={styles.resultsButtonLayout}
          onClick={onClose} dataX={'close-filter-overlay'}
          label={__(Number(totalCount), { totalCount })`job-filter-results-button-title`}
        />
      </div>
    </div>
  )
}

function OverlayHeader({ title, onClose }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentOverlayHeader}>
      <HeadingSm h={2} {...{ title }} />
      <button
        className={styles.overlayHeaderButton}
        type="button"
        data-x="close-overlay"
        aria-label={__`close-overlay`}
        onClick={onClose}
      >
        <Icon icon={iconCross} />
      </button>
    </div>
  )
}

function Search({ q, onSearch, placeholder }) {
  const { context, setOpen, isOpen, getFloatingProps } = useFloatingSearch()
  const { __ } = useTranslate()

  return (
    <>
      <IconButton
        icon={iconLookingGlass}
        text={__`search-form-field`}
        onClick={() => setOpen(true)}
        layoutClassName={styles.iconButtonLayout}
        dataX='open-search-overlay'
      >
        {Boolean(q) && (
          <FilterIndicator layoutClassName={styles.indicatorLayout} value={1} />
        )}
      </IconButton>

      {isOpen &&
        <FloatingPortal>
          <FloatingOverlay>
            <FloatingFocusManager {...{ context }}>
              <SearchOverlay
                initialValue={q}
                onSubmit={handleSubmit}
                {...{ onSearch, getFloatingProps, placeholder }}
              />
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      }
    </>
  )

  function handleSubmit(e) {
    onSearch(e)
    setOpen(false)
  }
}

function useFloatingSearch() {
  const [isOpen, setOpen] = React.useState(false)

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setOpen
  })

  const role = useRole(context, { role: 'dialog' })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const { getFloatingProps } = useInteractions([
    role, dismiss
  ])

  return {
    context,
    isOpen,
    setOpen,
    getFloatingProps: x => getFloatingProps({ ref: refs.setFloating, ...x })
  }
}

function SearchOverlay({ initialValue, onSubmit, getFloatingProps, placeholder = undefined }) {
  return (
    <div className={styles.component_rootSearchOverlay}>
      <ContainerLg>
        <div {...getFloatingProps()}>
          <FilterSearchField
            {...{ onSubmit, initialValue, placeholder }}
          />
        </div>
      </ContainerLg>
    </div>
  )
}

const IconButton = React.forwardRef(IconButtonImpl)
function IconButtonImpl({ dataX, icon, text, layoutClassName, onClick, children }, ref) {
  return (
    <button
      type="button"
      data-x={dataX}
      aria-label={text}
      className={cx(styles.componentIconButtonImpl, layoutClassName)}
      {...{ ref, onClick }}
    >
      {children}
      <span className={styles.iconButtonContent}>
        <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
        <span className={styles.buttonText}>{text}</span>
      </span>
    </button>
  )
}

function FilterIndicator({ layoutClassName, value }) {
  return (
    <div className={cx(styles.componentFilterIndicator, layoutClassName)}>
      {value ?? value}
    </div>
  )
}
