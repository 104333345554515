import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { animated, config, useSprings } from 'react-spring'
import { useElementSize } from '@kaliber/use-element-size'

import { trackFilterInteractionMultiValue, trackFilterInteraction } from '/analytics/user-interaction'
import { updateFilterValue } from '/machinery/updateFilterValue'
import { useListDropdown } from '/machinery/useListDropdown'
import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'

import iconCheck from '/images/icons/check.raw.svg'
import chevronIcon from '/images/icons/chevron-top.raw.svg'
import iconCross from '/images/icons/icon-cross.raw.svg'

import styles from './OverviewFilters.css'

export function OverviewFilters({ onFilterChange, filtersAndValues, onReset }) {
  const filtersSorted = React.useMemo(
    () => Object.fromEntries(
      Object.entries(filtersAndValues).sort(([, a], [, b]) => a.display_order - b.display_order)
    ),
    [filtersAndValues]
  )

  const activeFilters = mapActiveFilters({ filtersAndValues })

  return (
    <div className={styles.component}>
      {Boolean(activeFilters.length) && (
        <ActiveFilters {...{ onFilterChange, activeFilters, onReset }} />
      )}

      {Object.entries(filtersSorted)
        .filter(([, { display }]) => display !== 'none')
        .map(([filterId, { options, value, label, type, range }]) => {
          const isInitialOpen = Array.isArray(value) ? value.length > 0 : Boolean(value)

          return (
            <FilterGroup
              key={filterId}
              layoutClassName={styles.filterGroupLayout}
              {...{ options, filterId, onFilterChange, isInitialOpen, value, label, type, range }}
            />
          )
        })}
    </div>
  )

  function mapActiveFilters({ filtersAndValues }) {
    return Object.values(filtersAndValues).flatMap(({ activeFilterTags }) => {
      if (activeFilterTags) return activeFilterTags
      return []
    })
  }
}

function ActiveFilters({ activeFilters, onReset, onFilterChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentActiveFilters}>
      <div className={styles.activeFiltersHeader}>{__`selected-filters-title`}</div>
      <div className={styles.activeFilterPills}>
        {activeFilters.map(({ filterId, activeOptions, type, filterValue }) => (
          activeOptions.map(option => (
            <button
              className={styles.activeFilterPill}
              key={option.id}
              type="button"
              aria-label={__({ filterName: option.label })`reset-filter-label`}
              onClick={() => handleFilterRemoval(filterId, filterValue, option.id, type, option.subfilter)}
            >
              {option.label}
              <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
                <Icon icon={iconCross} />
              </span>
            </button>
          ))
        ))}

        <button
          className={styles.resetFiltersPill}
          type="button"
          aria-label={__`reset-filters-label`}
          onClick={onReset}
        >
          {__`reset-filters-label-pill`}
          <span className={cx(styles.iconContainer, styles.iconContainerLayout)}>
            <Icon icon={iconCross} />
          </span>
        </button>
      </div>
    </div>
  )

  function handleFilterRemoval(filterId, filterValue, optionId, filterType, subfilter = undefined) {
    if (['range_slider', 'select'].includes(filterType)) {
      onFilterChange({ [filterId]: null })
    } else if (filterType === 'multi-select' && subfilter) {
      const flattenedSubfilterOptions = subfilter.options.map(({ id }) => id) || []
      const filteredSubfilterValues = subfilter.value.filter(x => !flattenedSubfilterOptions.includes(x))
      onFilterChange({
        [filterId]: filterValue.filter(x => x !== optionId),
        [subfilter.filterId]: filteredSubfilterValues
      })
    } else {
      onFilterChange({
        [filterId]: filterValue.filter(x => x !== optionId),
      })
    }
  }
}

function FilterGroup({ layoutClassName, options, filterId, isInitialOpen, onFilterChange, value, type, range = undefined, label = undefined }) {
  const [isOpen, setOpen] = React.useState(isInitialOpen)
  const { size: { height }, ref: innerRef } = useElementSize()

  const { parentHandlers, childHandlers } = useTabNavigation({ options, onOpenChange: setOpen })
  const id = React.useId()

  return (
    <fieldset
      role='group'
      id={filterId}
      aria-labelledby={`field-label-${id}`}
      className={cx(styles.componentFilterGroup, layoutClassName)}
      {...parentHandlers}
    >
      <FilterHeader
        onClick={() => setOpen(!isOpen)}
        layoutClassName={styles.filterHeaderLayout}
        {...{ id, isOpen, filterId }}
      />

      <div
        className={styles.expandContainer}
        style={{ height: isOpen ? height + 'px' : 0 }}
        {...(!isOpen ? { inert: 'true' } : {})}
        {...childHandlers}
      >
        <div ref={innerRef}>
          {filterId === 'salary_scale' ?
            <FilterOptionsSalary {...{ id, options, value, isOpen, label, range }} onFilterChange={handleSalaryChange} onDragEnd={trackSalaryChange} />
            : filterId === 'country' ?
              <FilterOptionsCountry {...{ id, options, value, isOpen }} onFilterChange={handleCountryChange} />
              :
              filterId === 'tag' ?
                <FilterOptionsPill {...{ filterId, options, value, isOpen }} onFilterItemChange={handlePillChange} />
                : type === 'multi-select' ?
                  <FilterOptionsCheckbox {...{ filterId, options, value, isOpen }} onFilterItemChange={handleCheckboxChange} />
                  : null}
        </div>
      </div>
    </fieldset>
  )

  function handleSalaryChange({ min = null, max = null }) {
    const value = filterEmpty({ salary_scale_min: min, salary_scale_max: max })
    onFilterChange(value)
  }

  function trackSalaryChange({ min, max }) {
    trackFilterInteraction({
      name: 'salary',
      value: [min, max].filter(Boolean).join('-'),
      inputType: 'range_slider',
      categorySpecificValues: { filter_selected: true }
    })
  }

  function handleCountryChange(country) {
    trackFilterInteraction({
      name: 'country',
      value: country,
      inputType: 'single_select',
      categorySpecificValues: { filter_selected: true }
    })
    onFilterChange({ country })
  }

  function handlePillChange({ filterId, id }) {
    updateAndTrackItemChange({ type: 'pill', filterId, id })
  }

  function handleCheckboxChange({ filterId, id, value, extraFilters = undefined }) {
    const type = 'checkbox'
    const updatedFilterValue = updateFilterValue({ value, filterId, optionId: id })

    trackFilterInteractionMultiValue({
      name: filterId,
      value: id,
      inputType: type,
      selected: updatedFilterValue.includes(id),
    })

    onFilterChange({
      [filterId]: updatedFilterValue,
      ...extraFilters
    })
  }

  function updateAndTrackItemChange({ type, filterId, id }) {
    const updatedFilterValue = updateFilterValue({ value, filterId, optionId: id })

    trackFilterInteractionMultiValue({
      name: filterId,
      value: id,
      inputType: type,
      selected: updatedFilterValue.includes(id),
    })

    onFilterChange({
      [filterId]: updatedFilterValue,
    })
  }
}

function filterEmpty(x) {
  const values = Object.entries(x).filter(([_, v]) => v !== null && v !== undefined)
  return Object.fromEntries(values)
}

function useTabNavigation({ onOpenChange, options }) {
  const exceptions = ['salary_scale_min', 'salary_scale_max']

  return {
    parentHandlers: {
      onKeyDown: e => {
        if (e.key === 'Tab' && e.shiftKey && !isException(e.target.id)) {
          onOpenChange(false)
        }

        if (e.currentTarget.contains(e.relatedTarget)) {
          onOpenChange(false)
        }
      }
    },
    childHandlers: {
      onKeyDown: e => {
        const isLastItem = e.target?.id === options[options.length - 1]?.id
        if (e.key === 'Tab' && isLastItem) {
          e.stopPropagation()
          onOpenChange(x => !x)
        }
      }
    }
  }

  function isException(x) {
    return exceptions.includes(x)
  }
}

function FilterOptionsSalary({ id, options, label, range, value, isOpen, onFilterChange, onDragEnd }) {
  return (
    <div className={styles.componentFilterOptionsSalary} data-x='select-salary'>
      <SalarySlider
        ariaLabelledById={id}
        tabIndex={isOpen ? 0 : -1}
        onChange={onFilterChange}
        layoutClassName={styles.salarySliderLayout}
        {...{ options, label, value, range, onDragEnd }}
      />
    </div>
  )
}

function FilterOptionsCountry({ id, options, value, isOpen, onFilterChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFilterOptionsCountry} data-x='select-country'>
      <Select
        ariaLabelledById={id}
        tabIndex={isOpen ? 0 : -1}
        onChange={onFilterChange}
        placeholder={__`filter-country-placeholder`}
        layoutClassName={styles.selectLayout}
        {...{ options, value }}
      />
    </div>
  )
}

function FilterOptionsCheckbox({ filterId, options, value, isOpen, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsCheckbox}>
      {options.map(({ id, label, subfilter }) => (
        <FilterValueItem
          key={id}
          tabIndex={isOpen ? 0 : -1}
          onChange={onFilterItemChange}
          layoutClassName={styles.filterValueItemLayout}
          {...{ filterId, id, label, value, subfilter }}
        />)
      )}
    </div>
  )
}

function FilterOptionsPill({ filterId, options, value, onFilterItemChange }) {
  return (
    <div className={styles.componentFilterOptionsPill}>
      {options.map(({ id, label }) => (
        <Pill
          onChange={() => onFilterItemChange({ filterId, id })}
          checked={value.includes(id)}
          key={id}
          {...{ filterId, id, label }}
        />
      ))}
    </div>
  )
}

function FilterHeader({ id, onClick, isOpen, filterId, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      className={cx(styles.componentFilterHeader, layoutClassName)}
      aria-expanded={isOpen}
      data-x='open-filter-dropdown'
      {...{ onClick }}
    >
      <legend id={`field-label-${id}`} className={styles.filterHeader}>
        {__`filter-${filterId.replace('_', '-')}-title`}
      </legend>
      <span className={cx(styles.dropdownChevron, isOpen && styles.rotate)}>
        <Icon icon={chevronIcon} />
      </span>
    </button>
  )
}

function FilterValueItem({ filterId, id, label, onChange, tabIndex, value, subfilter = undefined, layoutClassName = undefined }) {
  const checked = value?.includes(id)

  return (
    <div className={layoutClassName}>
      <Checkbox onChange={handleChange} {...{ label, checked, id, tabIndex }} />
      {
        Boolean(subfilter?.options?.length && checked) &&
        <FilterOptionsCheckbox filterId={subfilter.filterId} options={subfilter.options} value={subfilter.value} onFilterItemChange={onChange} isOpen />
      }
    </div>
  )

  function handleChange() {
    if (subfilter?.options) {
      const flattenedSubfilterOptions = subfilter.options?.map(({ id }) => id) || []
      const filteredSubfilterValues = subfilter.value?.filter(x => !flattenedSubfilterOptions.includes(x))
      onChange({ value, filterId, id, extraFilters: { [subfilter.filterId]: filteredSubfilterValues } })
    } else {
      onChange({ value, filterId, id })
    }
  }
}

function Pill({ checked, id, label, onChange }) {
  return (
    <label htmlFor={id} className={cx(styles.componentPill, checked && styles.pillChecked)}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id }} />
      {label}
    </label>
  )
}

function Checkbox({ checked, id, label, tabIndex, onChange }) {
  return (
    <label htmlFor={id} className={styles.componentCheckbox}>
      <CheckboxInput layoutClassName={styles.checkboxInputLayout} {...{ onChange, checked, id, tabIndex }} />
      <div className={styles.checkboxLabel}>
        <div className={styles.checkboxLabelMain}>{label}</div>
      </div>
      <span className={cx(styles.checkboxIndicator, checked && styles.checkboxIndicatorChecked)}>
        {checked && <Icon icon={iconCheck} />}
      </span>
    </label>
  )
}

function CheckboxInput({ onChange, checked, id, tabIndex = undefined, layoutClassName = undefined }) {
  return (
    <input
      type='checkbox'
      data-x='apply-filter'
      className={cx(styles.componentCheckboxInput, layoutClassName)}
      {...{ onChange, checked, id, tabIndex }}
    />
  )
}

function SalarySlider({
  value,
  range,
  onChange,
  label,
  options,
  tabIndex,
  onDragEnd,
  ariaLabelledById = undefined,
  layoutClassName,
}) {

  return (
    <div
      aria-labelledby={ariaLabelledById}
      className={cx(styles.componentSalarySlider, layoutClassName)}
    >
      <Visual {...{ options, value, range }} />
      <Slider
        onMinChange={handleMinChange}
        onMaxChange={handleMaxChange}
        count={options.length}
        {...{ label, range, tabIndex, value, onDragEnd }}
      />
    </div>
  )

  function handleMinChange(x) {
    onChange({ min: x })
  }

  function handleMaxChange(x) {
    onChange({ max: x })
  }
}

function Visual({ options, value, range }) {
  const gap = 2
  const baseSize = 4
  const totalWidth = 300

  const highestValue = range.max
  const totalHeight = baseSize + totalWidth * (highestValue / 100)

  const fraction = (totalWidth - ((options.length - 1) * gap)) / options.length


  const [animation] = useSprings(options.length, i => ({
    fill: calculateRelativeSize(options[i].id) === 0
      ? isBetween(options[i].id) ? 'var(--color-gray-300)' : 'var(--color-gray-100)'
      : isBetween(options[i].id) ? 'var(--color-blue-300)' : 'var(--color-gray-200)',
    config: config.stiff
  }), [value.min, value.max])

  return (
    <svg viewBox={`0 0 ${totalWidth} ${totalHeight}`}>
      {options.map((x, i) => {
        const relativeSize = calculateRelativeSize(x.id) * totalHeight

        return (
          <animated.rect
            key={i}
            x={(i * (gap + fraction))}
            y={totalHeight - Math.max(baseSize + relativeSize, baseSize)}
            width={fraction}
            height={Math.max(baseSize + relativeSize, baseSize)}
            rx={2}
            fill={animation[i].fill}
          />
        )
      })}
    </svg>
  )

  function calculateRelativeSize(x) {
    const option = options.find(({ id }) => id === x).total || 0
    const highestValue = Math.max(...options.map(x => x.total))

    return option / highestValue
  }

  function isBetween(x) {
    return x >= value.min && x <= value.max
  }
}

function Slider({
  step = 1,
  range,
  value,
  count,
  label,
  onMinChange,
  onMaxChange,
  onDragEnd,
  tabIndex
}) {
  const fractionMin = ((value.min) - range.min) / (range.max - range.min)
  const fractionMax = ((value.max) - range.min) / (range.max - range.min)

  const highlightClipPath = `inset(0 calc(${(1 - fractionMax)} * 100%) 0 calc(${fractionMin} * 100%))`

  return (
    <div
      className={styles.componentSlider}
      style={{ '--count': count, '--clip-path': highlightClipPath }}
    >
      <div className={styles.track} />

      <Min
        value={value.min}
        fraction={fractionMin}
        salary={label.min}
        onChange={handleMinChange}
        onDragEnd={min => onDragEnd({ min, max: value.max })}
        layoutClassName={styles.minLayout}
        {...{ range, step, tabIndex }}
      />

      <Max
        value={value.max}
        fraction={fractionMax}
        salary={label.max}
        onChange={handleMaxChange}
        onDragEnd={max => onDragEnd({ max, min: value.min })}
        layoutClassName={styles.maxLayout}
        {...{ range, step, tabIndex }}
      />

      <div className={styles.labels}>
        <span>{label.min}</span>
        <span>{label.max}</span>
      </div>
    </div>
  )

  function handleMinChange(e) {
    const val = parseInt(e.currentTarget.value, 10)

    onMinChange(val)
    if (val >= value.max) onMaxChange(Math.min(range.max, val))
  }

  function handleMaxChange(e) {
    const val = parseInt(e.currentTarget.value, 10)

    onMaxChange(val)
    if (val <= value.min) onMinChange(Math.max(range.min, val))
  }
}


function Min({ fraction, salary, value, range, step, tabIndex, onChange, onDragEnd, layoutClassName }) {
  const { __ } = useTranslate()

  const paddedValue = value.toString().padStart(2, 0)
  const { max, min } = range

  return (
    <div className={cx(styles.componentMin, layoutClassName)} style={{ '--fraction': fraction }}>
      <input
        type="range"
        aria-live='polite'
        aria-label={__`filter-min-salary-scale`}
        aria-valuemin={min}
        aria-valuemax={max - 1}
        aria-valuenow={value}
        aria-describedby='salary-min-amount'
        aria-valuetext={__({ type: 'minimum', value: paddedValue, salary })`filter-scale`}
        className={styles.range}
        max={range.max}
        onMouseUp={handleDragEnd}
        onTouchEnd={handleDragEnd}
        {...{ min, step, value, tabIndex, onChange }}
      />
    </div>
  )

  function handleDragEnd(e) {
    onDragEnd(parseInt(e.currentTarget.value, 10))
  }
}

function Max({ fraction, salary, value, range, step, tabIndex, onChange, onDragEnd, layoutClassName }) {
  const { __ } = useTranslate()
  const { max, min } = range

  const paddedValue = value.toString().padStart(2, 0)

  return (
    <div className={cx(styles.componentMax, layoutClassName)} style={{ '--fraction': fraction }}>
      <input
        type="range"
        aria-live='polite'
        aria-label={__`filter-max-salary-scale`}
        aria-valuemin={min + 1}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-describedby='salary-max-amount'
        aria-valuetext={__({ type: 'maximum', value: paddedValue, salary })`filter-scale`}
        className={styles.range}
        min={range.min}
        onMouseUp={handleDragEnd}
        onTouchEnd={handleDragEnd}
        {...{ max, step, value, tabIndex, onChange }}
      />
    </div>
  )

  function handleDragEnd(e) {
    onDragEnd(parseInt(e.currentTarget.value, 10))
  }
}

function Select({ value, onChange, options, placeholder, tabIndex, ariaLabelledById = undefined, layoutClassName }) {
  const selectedIndex = options.findIndex(x => x.value === value)
  const valueLabel = options.find(x => x.id === value)?.label

  const {
    isOpen,
    setIsOpen,
    context,
    getFloatingProps,
    getReferenceProps,
    getItemProps,
    activeIndex,
  } = useListDropdown({ selectedIndex })

  return (
    <>
      <button
        type='button'
        aria-labelledby={ariaLabelledById}
        className={cx(styles.select, !valueLabel && styles.selectNoValue, layoutClassName)}
        {...getReferenceProps({ onClick: () => setIsOpen(!isOpen) })}
        {...{ tabIndex }}
      >
        <span>{valueLabel ?? placeholder}</span>
        <span className={cx(styles.selectChevron, isOpen && styles.selectChevronOpen)}><Icon icon={chevronIcon} /></span>
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager {...{ context }}>
            <ul className={styles.selectDropdown} {...getFloatingProps()}>
              {[{ id: null, label: placeholder }, ...options].map(({ id, label }, i) => (
                <li
                  key={id}
                  className={cx(styles.selectOption)}
                >
                  <button
                    {...getItemProps(i, label, {
                      onClick: () => {
                        onChange(id)
                        setIsOpen(false)
                      }
                    })}
                    className={cx(styles.selectOptionButton)}
                    data-active={i === activeIndex}
                  >
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
