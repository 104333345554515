import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import iconLookingGlass from '/images/icons/icon-looking-glass.raw.svg'
import arrowIcon from '/images/icons/arrow.raw.svg'
import iconCross from '/images/icons/icon-cross.raw.svg'

import styles from './FilterSearchField.css'

export function FilterSearchField({ initialValue, onSubmit, layoutClassName = undefined, placeholder = undefined }) {
  const [input, setInput] = React.useState(initialValue || '')
  const { __ } = useTranslate()
  const labelId = React.useId()

  React.useEffect(
    () => { setInput(initialValue || '') },
    [initialValue]
  )

  return (
    <form className={cx(styles.component, layoutClassName)} onSubmit={handleSearchChange}>
      <Icon icon={iconLookingGlass} layoutClassName={styles.searchIconLayout} />

      <div className={styles.search}>
        <label id={labelId} className={cx(styles.searchLabel, !input && styles.isVisible)}>
          {placeholder || __`filter-search-placeholder`}
        </label>

        <input
          type="search"
          aria-labelledby={labelId}
          className={styles.inputSearch}
          aria-label={__`search-form-field`}
          onChange={e => setInput(e.currentTarget.value)}
          data-x='job-filter-search'
          value={input}
        />
      </div>

      {input !== initialValue && (
        <button
          type='submit'
          className={styles.iconButton}
          data-x='click-to-search'
          aria-label={__`click-to-search`}
        >
          <Icon icon={arrowIcon} />
        </button>
      )}

      {input === initialValue && input !== '' && (
        <button
          type='button'
          className={styles.iconButton}
          data-x='click-to-search'
          aria-label={__`delete-search-value`}
          onClick={e => {
            setInput('')
            onSubmit()
          }}
        >
          <Icon icon={iconCross} />
        </button>
      )}
    </form>
  )

  function handleSearchChange(e) {
    e.preventDefault()
    onSubmit(input)
  }
}
